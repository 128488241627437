import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapType } from '../../../../shared/enums/heatmapType.enum';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import { Efficiency } from '../models/comparision-analysis.model';
import initialCompareEfficiency from './comparision-analysis-initial-state';

const compareEfficiencySlice = createSlice({
    name: 'compareEfficiencySlice',
    initialState: initialCompareEfficiency,
    reducers: {
        setEfficiencies(state, { payload }: PayloadAction<Efficiency[]>) {
            state.efficiencies = payload;
        },
        appendEfficiencies(state, { payload }: PayloadAction<Efficiency>) {
            if (state.efficiencies !== null) {
                state.efficiencies.push(payload);
            }
        },
        setEfficienciyType(state, { payload }: PayloadAction<"Working" | "Movement" | "Usage">) {
            state.efficiencyType = payload;
        },
        setComparedEfficiencies(state, { payload }: PayloadAction<Efficiency>) {
            state.comparedEfficieny = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setForklfts(state, { payload }: PayloadAction<string[]>) {
            state.forklits = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setPeriod(state, { payload }: PayloadAction<TimePeriod>) {
            state.period = payload;
        },
        setHeatmapType(state, { payload }: PayloadAction<HeatmapType>) {
            state.heatmapType = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setCompareType(state, { payload }: PayloadAction<"Company" | "Department" | "Forklift">) {
            state.compareType = payload;
        }
    }
});

export const {
    setEfficiencies,
    appendEfficiencies,
    setComparedEfficiencies,
    setEfficienciyType,
    setCompanies,
    setForklfts,
    setCreateDate,
    setEndDate,
    setPeriod,
    setHeatmapType,
    setDepartments,
    setShowFilters,
    setCompareType
} = compareEfficiencySlice.actions

export default compareEfficiencySlice.reducer