import { Dayjs } from "dayjs";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { DateModel } from "../../../../shared/models/date.model";
import { OperatorComparing } from "../models/operatorComparing.model";

export interface OperatorComparingInitialState {
    selectedKey: TimePeriod;
    dailyOperatorComparing: OperatorComparing | null;
    weeklyOperatorComparing: OperatorComparing | null;
    yearlyOperatorComparing: OperatorComparing | null;
    dailyUsageOperatorComparing: OperatorComparing | null;
    weeklyUsageOperatorComparing: OperatorComparing | null;
    yearlyUsageOperatorComparing: OperatorComparing | null;
    companies: string[];
    departments: string[];
    operators: string[];
    dates: DateModel;
    showFilters: boolean;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
}

const initialOperatorComparingState: OperatorComparingInitialState = {
    selectedKey: TimePeriod.Daily,
    dailyOperatorComparing: null,
    weeklyOperatorComparing: null,
    yearlyOperatorComparing: null,
    dailyUsageOperatorComparing: null,
    weeklyUsageOperatorComparing: null,
    yearlyUsageOperatorComparing: null,
    companies: [],
    departments: [],
    operators: [],
    dates: {
        startDate: null,
        endDate: null
    },
    showFilters: true,
    startDate: null,
    endDate: null
}

export default initialOperatorComparingState