import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectIsLoggedIn } from '../pages/utillity-pages/login/store/auth.selectors';
import { useNavigate } from "react-router-dom";
import { OpeningPagePath } from './path';
import { setActiveMenuGroupKey } from '../shared/global-store/global.slice';

const PublicRoute = () => {
   let navigate = useNavigate();
   const dispatch = useDispatch();
   const isLoggedIn = useSelector(selectIsLoggedIn);

   useEffect(() => {
      if (isLoggedIn) {
         dispatch(setActiveMenuGroupKey(2))
         return navigate(OpeningPagePath);
      }
   }, [isLoggedIn, navigate, dispatch]);

   return <Outlet />
}

export default PublicRoute;