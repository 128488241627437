import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialVenueManagementState from './venue-management-initial-state';
import { Venue } from '../models/venue-management';

const venueManagementSlice = createSlice({
    name: 'venueManagementSlice',
    initialState: initialVenueManagementState,
    reducers: {
        setSelectedCompany(state, { payload }: PayloadAction<string>) {
            state.selectedCompany = payload;
        },
        setSelectedVenues(state, { payload }: PayloadAction<Venue[]>) {
            state.venues = payload;
        },
        setSelectedVenue(state, { payload }: PayloadAction<Venue | null>) {
            state.selectedVenue = payload;
        }
    }
});

export const {
    setSelectedCompany,
    setSelectedVenues,
    setSelectedVenue
} = venueManagementSlice.actions

export default venueManagementSlice.reducer