import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialHeatmapState from './heatmap-initial-state';
import { Company } from '../../../../shared/models/company.model';
import { GeoJsonModel, HeatmapReport } from '../models/heatmap.model';
import { Forklift } from '../../../../shared/models/forklift.model';
import { Department } from '../../../../shared/models/department.model';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';

const heatmapSlice = createSlice({
    name: 'heatmapSlice',
    initialState: initialHeatmapState,
    reducers: {
        setSelectedCompany(state, { payload }: PayloadAction<string | null>) {
            state.selectedCompany = payload;
        },
        setHeatmapReport(state, { payload }: PayloadAction<HeatmapReport[]>) {
            state.heatmapReport = payload;
        },
        appendGeoJson(state, { payload }: PayloadAction<GeoJsonModel>) {
            state.geoJsons.push(payload);
        },
        setGeoJsons(state, { payload }: PayloadAction<GeoJsonModel[]>) {
            state.geoJsons = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setSelectedDepartments(state, { payload }: PayloadAction<string[]>) {
            state.selectedDepartments = payload;
        },
        setSelectedForklifts(state, { payload }: PayloadAction<string | null>) {
            state.selectedForklifts = payload;
        },
        setPeriod(state, { payload }: PayloadAction<TimePeriod>) {
            state.period = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
    }
});

export const {
    setSelectedCompany,
    setHeatmapReport,
    setGeoJsons,
    appendGeoJson,
    setShowFilters,
    setSelectedForklifts,
    setSelectedDepartments,
    setPeriod,
    setCreateDate,
    setEndDate
} = heatmapSlice.actions

export default heatmapSlice.reducer