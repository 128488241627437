export interface GlobalInitialState {
    lang: string;
    activeMenuItemKey: number;
    activeMenuGroupKey: number | null;
    isLightTheme: boolean;
    isMenuCollapsed: boolean;
    showNotif: boolean;
    noActionReportCount: number;
}

const initialGlobalState: GlobalInitialState = {
    lang: "tr",
    activeMenuItemKey: 1,
    activeMenuGroupKey: 1,
    isLightTheme: true,
    isMenuCollapsed: false,
    showNotif: true,
    noActionReportCount: 0
}

export default initialGlobalState