import React, { Suspense } from 'react';
import App from './app/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import reportWebVitals from './reportWebVitals';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './app/store';
import { createRoot } from 'react-dom/client';
import './index.scss';
import '../src/i18n';
import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
const container = document.getElementById('root');
const root = createRoot(container!)
const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: any) => {
        console.log('Query Error: ', error.isAxiosError ? error.toJSON() : error);
      }
    },
    mutations: {
      onError: (error: any) => {
        console.log('Query Error: ', error.isAxiosError ? error.toJSON() : error);
      }
    }
  }
});

const theme = createTheme({
  /** Put your mantine theme override here */
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme}>
          <Suspense>
            <App />
          </Suspense>
        </MantineProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
