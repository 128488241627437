import { AlarmReport } from "../../alarm-report/models/alarm-reports.model";

export interface AlarmReportsInitialState {
    selectedCompany: string;
    selectedAlarm: string;
    startDate: string | null;
    endDate: string | null;
    alarmReportList: AlarmReport[]
}

const initialAlarmReportsState: AlarmReportsInitialState = {
    selectedCompany: "0",
    selectedAlarm: "0",
    startDate: null,
    endDate: null,
    alarmReportList: []
}

export default initialAlarmReportsState