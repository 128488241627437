import { GlobalToken } from "antd/es/theme/interface";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import dayjs, { Dayjs } from "dayjs";
import { TFunction } from "i18next";

export const getOptions = (title: string, categories: string[], isLightTheme: boolean, token: GlobalToken, key: TimePeriod, t: TFunction<"translation", undefined, "translation">) => {
    let options = {
        chart: {
            type: 'bar',
            height: 500,
            stacked: true,
            toolbar: {
                show: true,
                export: {
                    csv: undefined,
                    svg: {
                        filename: title,
                        headerValue: title
                    },
                    png: {
                        filename: title,
                        headerValue: title
                    }
                }
            },
            zoom: {
                enabled: true,
                type: 'x',
            }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
            text: title,
            align: 'center',
            style: {
                color: token.colorText
              },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          }
        },
        xaxis: {
            type: key == TimePeriod.Weekly ? undefined : 'datetime',
            categories: categories,
            tickPlacement: 'off',
            labels: {
              show: true,
              datetimeUTC: true,
              format: key == TimePeriod.Monthly ? 'MMM' : 'dd MMM',
              formatter: key == TimePeriod.Weekly ? function (value: any, timestamp: any) {
                return `${dayjs(value).format("YYYY")}-${dayjs(value).week()}.`;
              } : undefined
            },
        },
        fill: {
          opacity: 1
        },
        tooltip: {
            enabled: true,
            theme: isLightTheme ? "light" : "dark",
            x: {
              formatter: key == TimePeriod.Daily ? function (value: any, timestamp: any) {
                return `${dayjs(value).format("DD MMM")}`;
              } : undefined
            }
        },
        colors: [token["volcano-8"], token["volcano-4"], token["green-8"], token["green-4"], token["lime-8"], token["lime-4"], token["orange-8"], token["orange-4"], token["cyan-8"], token["cyan-4"], token["geekblue-8"], token["geekblue-4"], token["purple-8"], token["purple-4"], token["magenta-8"], token["magenta-4"]],
        dataLabels: {
          enabled: true,
          formatter: function (value: any, timestamp: any) {
            if(value < 60)
            {
              return `${toHoursAndMinutes(value).minutes} ${t("common.m")}`;
            }
            return `${toHoursAndMinutes(value).hours} ${t("common.h")} ${toHoursAndMinutes(value).minutes} ${t("common.m")}`;
          },
          style: {
            fontSize: '10px'
          },
        },
        yaxis: {
            labels: {
              style: {
                colors: Array(categories.length).fill(token.colorText),
              },
              formatter: function (value: any, timestamp: any) {
                if(value < 60)
                {
                  return `${toHoursAndMinutes(value).minutes} ${t("common.minutes")}`;
                }
                return `${toHoursAndMinutes(value).hours} ${t("common.hours")} ${toHoursAndMinutes(value).minutes} ${t("common.minutes")}`;
              }
            }
        },
        theme: {
            mode: isLightTheme ? "light" : "dark",
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          offsetY: 5
        }
    }
    return options;
}

export const getAlternativeOptions = (title: string, categories: string[], isLightTheme: boolean, token: GlobalToken, key: TimePeriod, t: TFunction<"translation", undefined, "translation">) => {
  let options = {
      chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          toolbar: {
              show: true,
              export: {
                  csv: undefined,
                  svg: {
                      filename: title,
                      headerValue: title
                  },
                  png: {
                      filename: title,
                      headerValue: title
                  }
              }
          },
          zoom: {
              enabled: true,
              type: 'x',
          },
          stackType: '100%'
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
          text: title,
          align: 'center',
          style: {
              color: token.colorText
            },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
          type: key == TimePeriod.Weekly ? undefined : 'datetime',
          categories: categories,
          tickPlacement: 'off',
          labels: {
            show: true,
            datetimeUTC: true,
            format: key == TimePeriod.Monthly ? 'MMM' : 'dd MMM',
            formatter: key == TimePeriod.Weekly ? function (value: any, timestamp: any) {
              return `${dayjs(value).format("YYYY")}-${dayjs(value).week()}.`;
            } : undefined
          },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
          enabled: true,
          theme: isLightTheme ? "light" : "dark",
          x: {
            formatter: key == TimePeriod.Daily ? function (value: any, timestamp: any) {
              return `${dayjs(value).format("DD MMM")}`;
            } : undefined
          },
          y: {
            formatter: function (value: any, timestamp: any) {
              if(value < 60)
              {
                return `${toHoursAndMinutes(value).minutes} ${t("common.m")}`;
              }
              return `${toHoursAndMinutes(value).hours} ${t("common.h")} ${toHoursAndMinutes(value).minutes} ${t("common.m")}`;
            },
          }
      },
      colors: [token["green-5"], token["gold-5"], token["red-5"]],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px'
        },
        formatter: function (value: any, timestamp: any) {
          return Math.round(value) + '%';
        }
      },
      theme: {
          mode: isLightTheme ? "light" : "dark",
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 5
      }
  }
  return options;
}

export const getRatioOptions = (title: string, categories: string[], isLightTheme: boolean, token: GlobalToken, key: TimePeriod) => {
  let options = {
      chart: {
          type: 'bar',
          height: 500,
          toolbar: {
              show: true,
              export: {
                  csv: undefined,
                  svg: {
                      filename: title,
                      headerValue: title
                  },
                  png: {
                      filename: title,
                      headerValue: title
                  }
              }
          },
          zoom: {
              enabled: true,
              type: 'x',
          }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
          text: title,
          align: 'center',
          style: {
              color: token.colorText
            },
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
          type: key == TimePeriod.Weekly ? undefined : 'datetime',
          categories: categories,
          tickPlacement: 'off',
          labels: {
            show: true,
            datetimeUTC: false,
            format: key == TimePeriod.Monthly ? 'MMM' : 'dd MMM',
            formatter: key == TimePeriod.Weekly ? function (value: any, timestamp: any) {
              return `${dayjs(value).format("YYYY")}-${dayjs(value).week()}.`;
            } : undefined
          },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
          enabled: true,
          theme: isLightTheme ? "light" : "dark",
          y: {
            formatter: function (val:any) {
                return `${val}%`
            }
          }
      },
      colors: [token["volcano-4"], token["orange-4"], token["lime-4"], token["green-4"], token["cyan-4"], token["geekblue-4"], token["purple-4"], token["magenta-4"]],
      dataLabels: {
        enabled: true,
        formatter: function (val:any) {
          return `${val}%`
        }
      },
      yaxis: {
          labels: {
            style: {
              colors: Array(categories.length).fill(token.colorText),
            },
            formatter: (value:any) => { return `${value}%` },
          },
          max: 100,
          min: 0
      },
      theme: {
          mode: isLightTheme ? "light" : "dark",
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 5
      }
  }
  return options;
}

export const toHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
}

export const getSessionTitle = (key: TimePeriod, isAlternative?: boolean) => {
  if(isAlternative){
    switch (Number(key)) {
      case TimePeriod.Daily:
          return "section_title_day_alternative"
      case TimePeriod.Weekly:
          return "section_title_week_alternative"
      case TimePeriod.Monthly:
          return "section_title_month_alternative"
      default:
          return ""
    }
  }
  switch (Number(key)) {
      case TimePeriod.Daily:
          return "section_title_day"
      case TimePeriod.Weekly:
          return "section_title_week"
      case TimePeriod.Monthly:
          return "section_title_month"
      default:
          return ""
  }
}

export const getUsageSessionTitle = (key: TimePeriod) => {
  switch (Number(key)) {
      case TimePeriod.Daily:
          return "usage_section_title_day"
      case TimePeriod.Weekly:
          return "usage_section_title_week"
      case TimePeriod.Monthly:
          return "usage_section_title_month"
      default:
          return ""
  }
}

export const getTempCategories = (key: TimePeriod, startDate: Dayjs, endDate: Dayjs) => {
  let result: string[] = [];
  switch (Number(key)) {
    case TimePeriod.Daily:
      for (var d = dayjs(startDate); d.isBefore(dayjs(endDate).add(1, 'day')); d = d.add(1, 'day')) {
        result.push(d.format("MM/DD/YYYY"));
      }
      break;
    case TimePeriod.Weekly:
      let weekStart = getFirstDayOfWeekFromWeekNumber(Number(dayjs(startDate).format("YYYY")),dayjs(startDate).week())
      let weekEnd = getLastDayOfWeekFromWeekNumber(Number(dayjs(endDate).format("YYYY")),dayjs(endDate).week())
      for (var d = dayjs(weekStart); d.isBefore(dayjs(weekEnd)); d = d.add(1, 'week')) {
        result.push(d.format("MM/DD/YYYY"));
      }
      break;
    case TimePeriod.Monthly:
      let monthStart = getFirstDayOfMonth(dayjs(startDate).format("YYYY"),"01")
      let monthEnd = getLastDayOfMonth(dayjs(endDate).format("YYYY"),"12")
      for (var d = dayjs(monthStart); d.isBefore(dayjs(monthEnd)); d = d.add(1, 'month')) {
        result.push(d.format("MM/DD/YYYY"));
      }
      break;
    default:
      break;
  }
  return result;
}

export const getStartDate = (key: TimePeriod, startDate: string) => {
  let result: string = "";
  switch (Number(key)) {
    case TimePeriod.Daily:
      result = dayjs(startDate).hour(0).minute(0).second(0).millisecond(0).toISOString();
      break;
    case TimePeriod.Weekly:
      let weekStart = getFirstDayOfWeekFromWeekNumber(Number(dayjs(startDate).format("YYYY")),dayjs(startDate).week())
      result = weekStart.hour(0).minute(0).second(0).millisecond(0).toISOString();
      break;
    case TimePeriod.Monthly:
      let monthStart = getFirstDayOfMonth(dayjs(startDate).format("YYYY"),"01")
      result = monthStart.hour(0).minute(0).second(0).millisecond(0).toISOString();
      break;
    default:
      break;
  }
  return result;
}

export const getEndDate = (key: TimePeriod, endDate: string) => {
  let result: string = "";
  switch (Number(key)) {
    case TimePeriod.Daily:
      result = dayjs(endDate).hour(23).minute(59).second(59).millisecond(0).toISOString();
      break;
    case TimePeriod.Weekly:
      let weekStart = getLastDayOfWeekFromWeekNumber(Number(dayjs(endDate).format("YYYY")),dayjs(endDate).week())
      result = weekStart.hour(23).minute(59).second(59).millisecond(0).toISOString();
      break;
    case TimePeriod.Monthly:
      let monthStart = getLastDayOfMonth(dayjs(endDate).format("YYYY"),"12")
      result = monthStart.hour(23).minute(59).second(59).millisecond(0).toISOString();
      break;
    default:
      break;
  }
  return result;
}

const getFirstDayOfWeekFromWeekNumber = (year: number, weekNumber: number) => {
  // Create a Day.js object for the first day of the year
  const firstDayOfYear = dayjs(`${year}-01-01`);

  // Calculate the start of the week (Sunday) for the given year
  const startOfWeek = firstDayOfYear.day(1); // 0 corresponds to Sunday

  // Calculate the number of days to add to the startOfWeek to get the desired week
  const daysToAdd = (weekNumber - 1) * 7;

  // Calculate and return the first day of the target week
  return startOfWeek.add(daysToAdd, 'day');
}

const getLastDayOfWeekFromWeekNumber = (year: number, weekNumber: number) => {
  // Create a Day.js object for the first day of the year
  const firstDayOfYear = dayjs(`${year}-01-01`);

  // Calculate the start of the week (Sunday) for the given year
  const startOfWeek = firstDayOfYear.day(0); // 0 corresponds to Sunday

  // Calculate the number of days to add to the startOfWeek to get the desired week's end
  const daysToAdd = (weekNumber * 7);

  // Calculate and return the last day of the target week
  return startOfWeek.add(daysToAdd, 'day');
}

const getFirstDayOfMonth = (year: string, month: string) => {
  // Create a Day.js object for the first day of the given month
  return dayjs(`${year}-${month}-01`).startOf('month');
}

const getLastDayOfMonth = (year: string, month: string) => {
  // Create a Day.js object for the last day of the given month
  return dayjs(`${year}-${month}-01`).endOf('month');
}

const sumDataByEachIndex = (dataList: any[]) => {
  const result = [];
  let maxIndex = 0;

  for (const item of dataList) {
      maxIndex = Math.max(maxIndex, item.data.length);
  }

  for (let index = 0; index < maxIndex; index++) {
      let totalSum = 0;
      for (const item of dataList) {
          if (index < item.data.length) {
              totalSum += item.data[index];
          }
      }
      result[index] = totalSum;
  }

  return result;
}

const arrayDifference = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) {
      throw new Error("Arrays must have the same length");
  }

  const difference = [];
  for (let i = 0; i < arr1.length; i++) {
      difference.push(arr1[i] - arr2[i]);
  }

  return difference;
}

export const prepareAlternativeChartData = (data: any[], day: number, workingHours: number, t: TFunction<"translation", undefined, "translation">, forkliftCount: number) => {
  let working = data.filter(x => x.group === 'working');
  let workingData = sumDataByEachIndex(working);
  let movement = data.filter(x => x.group === 'movement');
  let movementData = sumDataByEachIndex(movement);
  let result = [
      {
          name: t('efficiency_summary.movement'),
          data: movementData
      },
      {
         name: t('efficiency_summary.working'),
         data: arrayDifference(workingData, movementData)
      },
      {
          name: t('efficiency_summary.not_working'),
          data: arrayDifference( new Array(workingData.length).fill(workingHours*60*(forkliftCount * day)), workingData)
      }
  ];

  return result;
}