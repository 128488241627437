import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialRealTimeMapViewState from './real-time-map-view-initial-state';
import { Floor } from '../../../admin-pages/indoor-management/models/indoor-management';

const realTimeMapViewSlice = createSlice({
    name: 'realTimeMapViewSlice',
    initialState: initialRealTimeMapViewState,
    reducers: {
        setSelectedCategory(state, { payload }: PayloadAction<"total" | "inMotion" | "working" | "notWorking">) {
            state.selectedCategory = payload;
        },
        setZoom(state, { payload }: PayloadAction<number>) {
            state.zoom = payload;
        },
        setFloor(state, { payload }: PayloadAction<Floor | undefined>) {
            state.selectedFloor = payload;
        },
        setVenue(state, { payload }: PayloadAction<string | undefined>) {
            state.selectedVenue = payload;
        }
    }
});

export const {
    setSelectedCategory,
    setZoom,
    setFloor,
    setVenue
} = realTimeMapViewSlice.actions

export default realTimeMapViewSlice.reducer