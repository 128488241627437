import { Floor } from "../../../admin-pages/indoor-management/models/indoor-management";
import { Venue } from "../../../admin-pages/venue-management/models/venue-management";

export interface RealTimeMapViewInitialState {
    selectedCategory: "total" | "inMotion" | "working" | "notWorking";
    selectedVenue: string | undefined;
    selectedFloor: Floor | undefined
    zoom: number
}

const initialRealTimeMapViewState: RealTimeMapViewInitialState = {
    selectedCategory: "total",
    selectedVenue: undefined,
    selectedFloor: undefined,
    zoom: 16
}

export default initialRealTimeMapViewState