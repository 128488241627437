import { Alarm } from "../models/alarm.model";

export interface AlarmTableInitialState {
    selectedCompany: string;
    alarmList: Alarm[]
}

const initialAlarmTableState: AlarmTableInitialState = {
    selectedCompany: "0",
    alarmList: []
}

export default initialAlarmTableState