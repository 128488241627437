import { AlarmSets } from "../models/alarm-sets.model";

export interface AlarmSetsInitialState {
    selectedCompany: string;
    alarmSetList: AlarmSets[]
}

const initialAlarmSetsState: AlarmSetsInitialState = {
    selectedCompany: "0",
    alarmSetList: []
}

export default initialAlarmSetsState