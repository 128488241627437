import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAlarmReportsState from './alarm-reports-initial-state';
import { AlarmReport } from '../models/alarm-reports.model';

const alarmReportsSlice = createSlice({
    name: 'alarmReportsSlice',
    initialState: initialAlarmReportsState,
    reducers: {
        setSelectedCompany(state, { payload }: PayloadAction<string>) {
            state.selectedCompany = payload;
        },
        setSelectedAlarm(state, { payload }: PayloadAction<string>) {
            state.selectedAlarm = payload;
        },
        setStartDate(state, { payload }: PayloadAction<string>) {
            state.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.endDate = payload;
        },
        setAlarmReportList(state, { payload }: PayloadAction<AlarmReport[]>) {
            state.alarmReportList = payload;
        }
    }
});

export const {
    setSelectedCompany,
    setSelectedAlarm,
    setStartDate,
    setEndDate,
    setAlarmReportList
} = alarmReportsSlice.actions

export default alarmReportsSlice.reducer