import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialOperatorComparingState from './operator-comparing-initial-state';
import { OperatorComparing } from '../models/operatorComparing.model';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import { Dayjs } from 'dayjs';

const operatorComparingSlice = createSlice({
    name: 'operatorComparingSlice',
    initialState: initialOperatorComparingState,
    reducers: {
        setKey(state, { payload }: PayloadAction<TimePeriod>) {
            state.selectedKey = payload;
        },
        setDailyOperatorComparing(state, { payload }: PayloadAction<OperatorComparing | null>) {
            state.dailyOperatorComparing = payload;
        },
        setWeeklyOperatorComparing(state, { payload }: PayloadAction<OperatorComparing | null>) {
            state.weeklyOperatorComparing = payload;
        },
        setYearlyOperatorComparing(state, { payload }: PayloadAction<OperatorComparing | null>) {
            state.yearlyOperatorComparing = payload;
        },
        setDailyUsageOperatorComparing(state, { payload }: PayloadAction<OperatorComparing | null>) {
            state.dailyUsageOperatorComparing = payload;
        },
        setWeeklyUsageOperatorComparing(state, { payload }: PayloadAction<OperatorComparing | null>) {
            state.weeklyUsageOperatorComparing = payload;
        },
        setYearlyUsageOperatorComparing(state, { payload }: PayloadAction<OperatorComparing | null>) {
            state.yearlyUsageOperatorComparing = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setOperators(state, { payload }: PayloadAction<string[]>) {
            state.operators = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setStartDateDayJS(state, { payload }: PayloadAction<Dayjs>) {
            state.startDate = payload;
        },
        setEndDateDayJS(state, { payload }: PayloadAction<Dayjs>) {
            state.endDate = payload;
        },
    }
});

export const {
    setKey,
    setDailyOperatorComparing,
    setWeeklyOperatorComparing,
    setYearlyOperatorComparing,
    setDailyUsageOperatorComparing,
    setWeeklyUsageOperatorComparing,
    setYearlyUsageOperatorComparing,
    setCompanies,
    setOperators,
    setCreateDate,
    setEndDate,
    setDepartments,
    setShowFilters,
    setStartDateDayJS,
    setEndDateDayJS
} = operatorComparingSlice.actions

export default operatorComparingSlice.reducer