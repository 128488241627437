import { Venue } from "../models/venue-management";

export interface VenueManagementInitialState {
    selectedCompany: string | null;
    venues: Venue[],
    selectedVenue: Venue | null;
}

const initialVenueManagementState: VenueManagementInitialState = {
    selectedCompany: null,
    venues: [],
    selectedVenue: null
}

export default initialVenueManagementState