import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { Company } from "../../../../shared/models/company.model";
import { DateModel } from "../../../../shared/models/date.model";
import { Department } from "../../../../shared/models/department.model";
import { Forklift } from "../../../../shared/models/forklift.model";
import { GeoJsonModel, HeatmapReport } from "../models/heatmap.model";

export interface HeatmapInitialState {
    selectedCompany: string | null;
    selectedDepartments: string[];
    selectedForklifts: string | null;
    heatmapReport: HeatmapReport[];
    geoJsons: GeoJsonModel[];
    showFilters: boolean;
    period: TimePeriod;
    dates: DateModel;
}

const initialHeatmapState: HeatmapInitialState = {
    selectedCompany: null,
    selectedDepartments: [],
    selectedForklifts: null,
    heatmapReport: [],
    geoJsons: [],
    showFilters: true,
    period: TimePeriod.Daily,
    dates: {
        startDate: null,
        endDate: null
    },
}

export default initialHeatmapState