import axios, { AxiosRequestHeaders } from "axios";
import store from "../../app/store";
//import { refreshToken } from "../../pages/login/services/login.service";
import { setToken } from "../../pages/utillity-pages/login/store/auth.slice";
import { message } from "antd";
import { setIsNotifShow } from "../global-store/global.slice";

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
})

Api.interceptors.request.use(async (config) => {
    if (store.getState().authState.token !== null){
        config.headers = {
            'Authorization': `Token ${store.getState().authState.token}`
        } as AxiosRequestHeaders
    }
    return config;
});

Api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      if (error.response.status === 401 && !originalRequest._retry) {
        store.dispatch(setToken(null));
        /*originalRequest._retry = true;
  
        const tokenRefreshed = await refreshToken();
        if (tokenRefreshed) {
          originalRequest.headers.Authorization = `Token ${store.getState().authState.token}`;
          return axios(originalRequest);
        }*/
        message.warning("Oturum Açma Süreniz Sona Erdi, Tekrar Giriş Yapınız.");
        store.dispatch(setIsNotifShow(false))
      }
      return Promise.reject(error);
    }
  );
export default Api;