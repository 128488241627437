import { RootState } from "../../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { Company } from "../../../../shared/models/company.model";
import { Department } from "../../../../shared/models/department.model";
import { Forklift } from "../../../../shared/models/forklift.model";
import { UserRole } from "../../../../shared/enums/userRoles.enum";
import { User } from "../../../admin-pages/admin-view/models/user.model";
import { Device } from "../../../admin-pages/admin-view/models/device.model";
import { Operator } from "../../../admin-pages/admin-view/models/operator.model";
import { ForkliftTag } from "../../../admin-pages/admin-view/models/tag.model";
import { Alarm } from "../../../alarms/alarm-table/models/alarm.model";
import { AlarmSets } from "../../../alarms/alarm-sets/models/alarm-sets.model";
import { Organization } from "../../../../shared/models/organization.model";

export const authState = (state: RootState) => state.authState;

export const selectToken = createSelector(
    authState,
    state => state.token as string
)
export const selectIsLoggedIn = createSelector(
    authState,
    state => state.token !== null ? true : false as boolean
)

export const selectUserName = createSelector(
    authState,
    state => state.userName as string
)

export const selectRole = createSelector(
    authState,
    state => state.role as UserRole
)

export const selectOrganizations = createSelector(
    authState,
    state => state.organizations as Organization[]
)


export const selectCompanies = createSelector(
    authState,
    state => state.companies as Company[]
)

export const selectDepartments = createSelector(
    authState,
    state => state.departments as Department[]
)

export const selectForklifts = createSelector(
    authState,
    state => state.forklifts as Forklift[]
)

export const selectUserId = createSelector(
    authState,
    state => state.userId as string
)

export const selectUser = createSelector(
    authState,
    state => state.user as User
)

export const selectUserEmail = createSelector(
    authState,
    state => state.userEmail as string
)

export const selectPasswordRecoveryStep = createSelector(
    authState,
    state => state.passwordRecoveryStep as number | null
)

export const selectDevices = createSelector(
    authState,
    state => state.devices as Device[]
)

export const selectOperators = createSelector(
    authState,
    state => state.operators as Operator[]
)

export const selectTags = createSelector(
    authState,
    state => state.tags as ForkliftTag[]
)

export const selectUsers = createSelector(
    authState,
    state => state.users as User[]
)

export const selectAlarms = createSelector(
    authState,
    state => state.alarms as Alarm[]
)

export const selectAlarmSets = createSelector(
    authState,
    state => state.alarmSets as AlarmSets[]
)