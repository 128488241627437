import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialIndoorManagementState from './indoor-management-initial-state';
import { Floor } from '../models/indoor-management';

const indoorManagementSlice = createSlice({
    name: 'indoorManagementSlice',
    initialState: initialIndoorManagementState,
    reducers: {
        setSelectedCompany(state, { payload }: PayloadAction<string>) {
            state.selectedCompany = payload;
        },
        setSelectedFloors(state, { payload }: PayloadAction<Floor[]>) {
            state.floors = payload;
        },
        setSelectedFloor(state, { payload }: PayloadAction<Floor | null>) {
            state.selectedFloor = payload;
        }
    }
});

export const {
    setSelectedCompany,
    setSelectedFloors,
    setSelectedFloor
} = indoorManagementSlice.actions

export default indoorManagementSlice.reducer