import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialOperatorAnalysisState from './operator-analysis-initial-state';
import { OperatorAnalysis } from '../models/operatorAnalysis.model';
import { TimePeriod } from '../../../../shared/enums/timePeriods.enum';
import { Dayjs } from 'dayjs';

const operatorAnalysisSlice = createSlice({
    name: 'operatorAnalysisSlice',
    initialState: initialOperatorAnalysisState,
    reducers: {
        setKey(state, { payload }: PayloadAction<TimePeriod>) {
            state.selectedKey = payload;
        },
        setDailyOperatorAnalysis(state, { payload }: PayloadAction<OperatorAnalysis | null>) {
            state.dailyOperatorAnalysis = payload;
        },
        setWeeklyOperatorAnalysis(state, { payload }: PayloadAction<OperatorAnalysis | null>) {
            state.weeklyOperatorAnalysis = payload;
        },
        setYearlyOperatorAnalysis(state, { payload }: PayloadAction<OperatorAnalysis | null>) {
            state.yearlyOperatorAnalysis = payload;
        },
        setDailyUsageOperatorAnalysis(state, { payload }: PayloadAction<OperatorAnalysis[]>) {
            state.dailyUsageOperatorAnalysis = payload;
        },
        setWeeklyUsageOperatorAnalysis(state, { payload }: PayloadAction<OperatorAnalysis[]>) {
            state.weeklyUsageOperatorAnalysis = payload;
        },
        setYearlyUsageOperatorAnalysis(state, { payload }: PayloadAction<OperatorAnalysis[]>) {
            state.yearlyUsageOperatorAnalysis = payload;
        },
        setCompanies(state, { payload }: PayloadAction<string[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<string[]>) {
            state.departments = payload;
        },
        setOperators(state, { payload }: PayloadAction<string[]>) {
            state.operators = payload;
        },
        setCreateDate(state, { payload }: PayloadAction<string>) {
            state.dates.startDate = payload;
        },
        setEndDate(state, { payload }: PayloadAction<string>) {
            state.dates.endDate = payload;
        },
        setShowFilters(state, { payload }: PayloadAction<boolean>) {
            state.showFilters = payload;
        },
        setStartDateDayJS(state, { payload }: PayloadAction<Dayjs>) {
            state.startDate = payload;
        },
        setEndDateDayJS(state, { payload }: PayloadAction<Dayjs>) {
            state.endDate = payload;
        },
    }
});

export const {
    setKey,
    setDailyOperatorAnalysis,
    setWeeklyOperatorAnalysis,
    setYearlyOperatorAnalysis,
    setDailyUsageOperatorAnalysis,
    setWeeklyUsageOperatorAnalysis,
    setYearlyUsageOperatorAnalysis,
    setCompanies,
    setOperators,
    setCreateDate,
    setEndDate,
    setDepartments,
    setShowFilters,
    setStartDateDayJS,
    setEndDateDayJS
} = operatorAnalysisSlice.actions

export default operatorAnalysisSlice.reducer