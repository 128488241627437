import Api from "../../../../shared/services/api"
import { AlarmReport } from "../models/alarm-reports.model";

export const editAlarmReport = async (AlarmReportReq: AlarmReport): Promise<void> => {
    const result = await Api.put(`/alarmreports`, AlarmReportReq);
    return result.data;
}

export const deleteAlarmReport = async (AlarmReportId: string): Promise<void> => {
    const result = await Api.delete(`/alarmreports/${AlarmReportId}`);
    return result.data;
}

export const getAlarmReports = async (companyIds: string[], userId: string, startDate: string|null, endDate: string|null): Promise<AlarmReport[]> => {
    if(!!startDate && !!endDate)
    {
        const result = await Api.get(`/alarmreports?${companyIds.map((n) => `ids=${n}`).join('&')}&userId=${userId}&startDate=${startDate}&endDate=${endDate}`);
        return result.data;
    }
    const result = await Api.get(`/alarmreports?${companyIds.map((n) => `ids=${n}`).join('&')}&userId=${userId}`);
    return result.data;
}

export const getAlarmReportsCount = async (userId: string): Promise<number> => {
    const result = await Api.get(`/alarmreports/count?&userId=${userId}`);
    return result.data;
}