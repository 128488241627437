import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './global-initial-state';

const globalSlice = createSlice({
    name: 'globalSlice',
    initialState: initialGlobalState,
    reducers: {
        setLang(state, { payload }: PayloadAction<string>) {
            state.lang = payload;
        },
        setActiveMenuItemKey(state, { payload }: PayloadAction<number>) {
            state.activeMenuItemKey = payload;
        },
        setActiveMenuGroupKey(state, { payload }: PayloadAction<number | null>) {
            state.activeMenuGroupKey = payload;
        },
        setIsLightTheme(state, { payload }: PayloadAction<boolean>) {
            state.isLightTheme = payload;
        },
        setIsMenuCollapsed(state, { payload }: PayloadAction<boolean>) {
            state.isMenuCollapsed = payload;
        },
        setIsNotifShow(state, { payload }: PayloadAction<boolean>) {
            state.showNotif = payload;
        },
        setNoActionReportCount(state, { payload }: PayloadAction<number>) {
            state.noActionReportCount = payload;
        }
    }
});

export const {
    setLang,
    setActiveMenuItemKey,
    setActiveMenuGroupKey,
    setIsLightTheme,
    setIsMenuCollapsed,
    setIsNotifShow,
    setNoActionReportCount
} = globalSlice.actions

export default globalSlice.reducer