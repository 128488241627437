import React from 'react';
import { Menu as AntdMenu, Badge, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectActiveMenuGroup, selectActiveMenuItem, selectIsLightTheme, selectNoActionReportCount } from '../../global-store/global.selectors';
import { selectRole } from '../../../pages/utillity-pages/login/store/auth.selectors';
import { getMenus } from './config/menu-utils';
import { MenuItem } from './models/menuItem.model';
import { AlarmReportsPath } from '../../../app/path';
const Menu = () => {
    const activeMenuItem = useSelector(selectActiveMenuItem);
    const activeMenuGroup = useSelector(selectActiveMenuGroup);
    const isLightTheme = useSelector(selectIsLightTheme);
    const role = useSelector(selectRole);
    const noActionReportCount = useSelector(selectNoActionReportCount);
    let navigate = useNavigate();
    const { t } = useTranslation();
    let items = (getMenus()).filter(x => x.roles.filter(y => y === role).length > 0);

    const getIcon = (item: MenuItem) => {
        if(item.link === AlarmReportsPath)
        {
            return <Badge style={{ fontSize: "0.875rem" }} size="small" className='flex justify-center items-center text-sm' count={noActionReportCount}>{React.createElement(item.icon)}</Badge>
        } else if (item.label === "alarmsOverview") {
            return <Badge dot={noActionReportCount > 0}>{React.createElement(item.icon)}</Badge>
            
        }
        return React.createElement(item.icon);
    }

    return (
        <AntdMenu theme={isLightTheme ? "light" : "dark"} mode="inline"
            selectedKeys={activeMenuItem ? [activeMenuItem.toString()] : []}
            defaultOpenKeys={activeMenuGroup ? [activeMenuGroup.toString()] : []}
            items={items.filter(x => !x.isDisabled).map(
                (item) => ({
                    key: item.id,
                    icon: getIcon(item),
                    label: <Tooltip title={item.children?.length === 0 ? t(`menu.${item.link}`) : t(`menu.${item.label}`)}><span>{item.children?.length === 0 ? t(`menu.${item.link}`) : t(`menu.${item.label}`)}</span></Tooltip>,
                    children: item.children?.length !== 0 ? item.children?.filter(x => x.roles.filter(y => y === role).length > 0).map((children) => ({
                        key: children.id,
                        icon: getIcon(children),
                        label: <Tooltip title={children.children?.length === 0 ? t(`menu.${children.link}`) : t(`menu.${children.label}`)}><span>{children.children?.length === 0 ? t(`menu.${children.link}`) : t(`menu.${children.label}`)}</span></Tooltip>,
                        onClick: children.children?.length === 0 ? () => navigate(children.link) : undefined,
                        type: children.children?.length === 0 ? undefined : "group",
                        children: children.children?.length !== 0 ? children.children?.filter(x => x.roles.filter(y => y === role).length > 0).map((grandChildren) => ({
                            key: grandChildren.id,
                            icon: getIcon(grandChildren),
                            label: <Tooltip title={t(`menu.${grandChildren.link}`)}><span>{t(`menu.${grandChildren.link}`)}</span></Tooltip>,
                            onClick: () => navigate(grandChildren.link)
                        })) : undefined,
                    })) : undefined,
                    onClick: item.children?.length === 0 ? () => navigate(item.link) : undefined,
                }),
            )}
        />
    );
}

export default Menu;