import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialForkliftTrackingState from './forklift-tracking-initial-state';

const forkliftTrackingSlice = createSlice({
    name: 'forkliftTrackingSlice',
    initialState: initialForkliftTrackingState,
    reducers: {
        setSelectedCategory(state, { payload }: PayloadAction<"total" | "inMotion" | "working" | "notWorking">) {
            state.selectedCategory = payload;
        }
    }
});

export const {
    setSelectedCategory,
} = forkliftTrackingSlice.actions

export default forkliftTrackingSlice.reducer