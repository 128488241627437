import Api from "../../../../shared/services/api"
import { UsageEfficiencyRequest, EfficiencyData, WorkingEfficiencyRequest, MovementEfficiencyRequest } from "../models/efficiency.model";

export const getUsageData = async (usageEfficiencyRequest: UsageEfficiencyRequest): Promise<EfficiencyData[]> => {
    const result = await Api.get(`/reports/usage-efficiency?${usageEfficiencyRequest.companyIds.map((n) => `companyIdList=${n}`).join('&')}&${usageEfficiencyRequest.forkliftIds.map((n) => `forkliftIdList=${n}`).join('&')}&${usageEfficiencyRequest.departmentIds.map((n) => `departmentIdList=${n}`).join('&')}&startDate=${usageEfficiencyRequest.startDate}&endDate=${usageEfficiencyRequest.endDate}`);
    return result.data;
}

export const getWorkingData = async (workingEfficiencyRequest: WorkingEfficiencyRequest): Promise<EfficiencyData[]> => {
    const result = await Api.get(`/reports/working-efficiency-v2?${workingEfficiencyRequest.companyIds.map((n) => `companyIdList=${n}`).join('&')}&${workingEfficiencyRequest.forkliftIds.map((n) => `forkliftIdList=${n}`).join('&')}&${workingEfficiencyRequest.departmentIds.map((n) => `departmentIdList=${n}`).join('&')}&startDate=${workingEfficiencyRequest.startDate}&endDate=${workingEfficiencyRequest.endDate}`,);
    return result.data;
}

export const getMovementData = async (movementEfficiencyRequest: MovementEfficiencyRequest): Promise<EfficiencyData[]> => {
    const result = await Api.get(`/reports/movement-efficiency-v2?${movementEfficiencyRequest.companyIds.map((n) => `companyIdList=${n}`).join('&')}&${movementEfficiencyRequest.forkliftIds.map((n) => `forkliftIdList=${n}`).join('&')}&${movementEfficiencyRequest.departmentIds.map((n) => `departmentIdList=${n}`).join('&')}&startDate=${movementEfficiencyRequest.startDate}&endDate=${movementEfficiencyRequest.endDate}`);
    return result.data;
}