import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { selectIsLoggedIn, selectRole } from '../pages/utillity-pages/login/store/auth.selectors';
import { useNavigate } from "react-router-dom";
import { OpeningPagePath, LoginPath } from './path';
import Layout from '../shared/layout/layout';
import { MenuItem } from '../shared/components/menu/models/menuItem.model';
import { setActiveMenuGroupKey, setActiveMenuItemKey } from '../shared/global-store/global.slice';
import { getMenus } from '../shared/components/menu/config/menu-utils';
const PrivateRoute = () => {
   let items = getMenus() as MenuItem[];
   let navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const isLoggedIn = useSelector(selectIsLoggedIn);
   const role = useSelector(selectRole);

   useEffect(() => {
      if (!isLoggedIn) {
         return navigate(LoginPath);
      }

      if (role === null) {
         return navigate(OpeningPagePath);
      }

      if (location.pathname === "/") {
         return navigate(OpeningPagePath);
      }

      items.forEach((item) => {
         if (item.link === location.pathname.slice(1) && item.roles.filter(x => x === role).length === 0) {
            return navigate(OpeningPagePath);
         }
         if (item.link === location.pathname.slice(1)) {
            dispatch(setActiveMenuGroupKey(null))
            return dispatch(setActiveMenuItemKey(item.id))
         }
         item.children?.forEach ((children)  => {
            if (children.link === location.pathname.slice(1) && children.roles.filter(x => x === role).length === 0) {
               return navigate(OpeningPagePath);
            }
            if (children.link === location.pathname.slice(1)) {
               dispatch(setActiveMenuItemKey(children.id))
               dispatch(setActiveMenuGroupKey(item.id))
            }

            children.children?.forEach ((grandChildren)  => {
               if (grandChildren.link === location.pathname.slice(1) && grandChildren.roles.filter(x => x === role).length === 0) {
                  return navigate(OpeningPagePath);
               }
               if (grandChildren.link === location.pathname.slice(1)) {
                  dispatch(setActiveMenuItemKey(grandChildren.id))
                  dispatch(setActiveMenuGroupKey(item.id))
               }
            })
         })
      })
   }, [isLoggedIn, navigate, items, location.pathname, role, dispatch]);

   return <Layout><Outlet /></Layout>
}

export default PrivateRoute;