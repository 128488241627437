import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAlarmTableState from './alarm-initial-state';
import { Alarm } from '../models/alarm.model';

const alarmTableSlice = createSlice({
    name: 'alarmTableSlice',
    initialState: initialAlarmTableState,
    reducers: {
        setSelectedCompany(state, { payload }: PayloadAction<string>) {
            state.selectedCompany = payload;
        },
        setAlarmList(state, { payload }: PayloadAction<Alarm[]>) {
            state.alarmList = payload;
        }
    }
});

export const {
    setSelectedCompany,
    setAlarmList
} = alarmTableSlice.actions

export default alarmTableSlice.reducer