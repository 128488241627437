import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../../../../shared/models/company.model';
import { Department } from '../../../../shared/models/department.model';
import { Forklift } from '../../../../shared/models/forklift.model';
import initialAuthState from './auth-initial-state';
import { UserRole } from '../../../../shared/enums/userRoles.enum';
import { User } from '../../../admin-pages/admin-view/models/user.model';
import { Device } from '../../../admin-pages/admin-view/models/device.model';
import { Operator } from '../../../admin-pages/admin-view/models/operator.model';
import { ForkliftTag } from '../../../admin-pages/admin-view/models/tag.model';
import { Alarm } from '../../../alarms/alarm-table/models/alarm.model';
import { AlarmSets } from '../../../alarms/alarm-sets/models/alarm-sets.model';
import { Organization } from '../../../../shared/models/organization.model';

const authSlice = createSlice({
    name: 'authSlice',
    initialState: initialAuthState,
    reducers: {
        setToken(state, { payload }: PayloadAction<string | null>) {
            state.token = payload;
        },
        setUserName(state, { payload }: PayloadAction<string | null>) {
            state.userName = payload;
        },
        setRole(state, { payload }: PayloadAction<UserRole>) {
            state.role = payload;
        },
        setOrganizations(state, { payload }: PayloadAction<Organization[]>) {
            state.organizations = payload;
        },
        setCompanies(state, { payload }: PayloadAction<Company[]>) {
            state.companies = payload;
        },
        setDepartments(state, { payload }: PayloadAction<Department[]>) {
            state.departments = Array.from(new Set(payload));
        },
        setForklifts(state, { payload }: PayloadAction<Forklift[]>) {
            state.forklifts = payload;
        },
        setUserId(state, { payload }: PayloadAction<string>) {
            state.userId = payload;
        },
        setUser(state, { payload }: PayloadAction<User | null>) {
            state.user = payload;
        },
        setChangePassword(state, { payload }: PayloadAction<string>) {
            state.token = null;
            state.passwordRecoveryStep = 0;
            state.userEmail = payload;
        },
        setPasswordRecoveryStep(state, { payload }: PayloadAction<number | null>) {
            state.passwordRecoveryStep = payload;
        },
        setDevices(state, { payload }: PayloadAction<Device[]>) {
            state.devices = payload;
        },
        setOperators(state, { payload }: PayloadAction<Operator[]>) {
            state.operators = payload;
        },
        setTags(state, { payload }: PayloadAction<ForkliftTag[]>) {
            state.tags = payload;
        },
        setUsers(state, { payload }: PayloadAction<User[]>) {
            state.users = payload;
        },
        setAlarms(state, { payload }: PayloadAction<Alarm[]>) {
            state.alarms = payload;
        },
        setAlarmSets(state, { payload }: PayloadAction<AlarmSets[]>) {
            state.alarmSets = payload;
        }
    }
});

export const {
    setToken,
    setUserName,
    setRole,
    setOrganizations,
    setCompanies,
    setDepartments,
    setForklifts,
    setUserId,
    setUser,
    setChangePassword,
    setPasswordRecoveryStep,
    setDevices,
    setOperators,
    setTags,
    setUsers,
    setAlarms,
    setAlarmSets
} = authSlice.actions

export default authSlice.reducer