import { Floor } from "../models/indoor-management";

export interface IndoorManagementInitialState {
    selectedCompany: string | null;
    floors: Floor[],
    selectedFloor: Floor | null;
}

const initialIndoorManagementState: IndoorManagementInitialState = {
    selectedCompany: null,
    floors: [],
    selectedFloor: null
}

export default initialIndoorManagementState