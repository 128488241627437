import { notification } from "antd";
import { HeatmapType } from "../enums/heatmapType.enum";
import { HeatmapData } from "../models/heatmap.model";
import { EfficiencyData as UsageEfficiencyData, UsageEfficiency, UsageEfficiencyRequest, MovementEfficiencyData, WorkingEfficiencyData, MovementEfficiency, WorkingEfficiency, MovementEfficiencyRequest, WorkingEfficiencyRequest } from "../../pages/efficiencies/efficiency/models/efficiency.model";
import store from "../../app/store";
import dayjs from "dayjs";
import * as XLSX from 'xlsx';
import { Forklift } from "../models/forklift.model";
import { ForkliftTag } from "../../pages/admin-pages/admin-view/models/tag.model";
import { Department } from "../models/department.model";
import { Company } from "../models/company.model";
import { TFunction } from "i18next";
import { useMutation } from "react-query";
import { getMovementData } from "../../pages/efficiencies/efficiency/services/efficiency.service";
import { setMovementEfficiency, setForklfts as setForklftsMovement, setDepartments as setDepartmentsMovement, setCompanies as setCompaniesMovement, setTags as setTagsMovement, setPeriod as setPeriodMovement, setHeatmapType as setHeatmapTypeMovement, setShowFilters as setShowFiltersMovement } from "../../pages/efficiencies/efficiency/store/movementEfficiency.slice";
import { getWorkingData } from "../../pages/efficiencies/efficiency/services/efficiency.service";
import { setWorkingEfficiency, setForklfts as setForklftsWorking, setDepartments as setDepartmentsWorking, setCompanies as setCompaniesWorking, setTags as setTagsWorking, setPeriod as setPeriodWorking, setHeatmapType as setHeatmapTypeWorking, setShowFilters as setShowFiltersWorking } from "../../pages/efficiencies/efficiency/store/workingEfficiency.slice";
import { getUsageData } from "../../pages/efficiencies/efficiency/services/efficiency.service";
import { setUsageEfficiency, setForklfts as setForklftsUsage, setDepartments as setDepartmentsUsage, setCompanies as setCompaniesUsage, setTags as setTagsUsage, setPeriod as setPeriodUsage, setHeatmapType as setHeatmapTypeUsage, setShowFilters as setShowFiltersUsage } from "../../pages/efficiencies/efficiency/store/usageEfficiency.slice";
import { TimePeriod } from "../enums/timePeriods.enum";
import { EfficienyType } from "../enums/efficiencyType.enum";
import { EfficiencySummary } from "../../pages/efficiencies/efficiency-summary/models/efficiencySummary.model";
import duration from 'dayjs/plugin/duration';
import { toHoursAndMinutes } from "../../pages/efficiencies/efficiency-summary/config/config";
dayjs.extend(duration);

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotification = (type: NotificationType, message: string, description: string) => {
    if (store.getState().globalState.showNotif){
        notification[type]({
            message: message,
            description: description
        });
    }
};

export const prepareHeatmapLabels = (heatmapType: HeatmapType) => {
    let labels = [];
    if (heatmapType === HeatmapType.Hourly) {
        for (let i = 0; i < 24; i++) {
            labels.push(`${('0' + i).slice(-2)}:00 - ${('0' + ((i + 1) > 23 ? 0 : (i + 1))).slice(-2)}:00`);
        }
    } else {
        for (let i = 0; i < 24; i = i + 8) {
            labels.push(`${('0' + i).slice(-2)}:00 - ${('0' + ((i + 8) > 23 ? 0 : (i + 8))).slice(-2)}:00`);
        }
    }
    return labels.reverse();
}

export const getHeatmapData = (heatmapData: HeatmapData[], heatmapType: HeatmapType) => {
    if (heatmapData === null || heatmapData.length === 0) {
        return;
    }

    let labels = prepareHeatmapLabels(heatmapType);

    let tempData: any = [];

    labels.forEach((label) => {
        let hourlyData = {
            name: label,
            data: [],
        }
        tempData.push(hourlyData)
    });

    heatmapData.forEach(dailyData => {
        dailyData.value.forEach((value, index) => {
            tempData[index]?.data.push({ x: dailyData.date, y: value > 100 ? 100 : value === null ? -1 : value })
        })
    });

    return tempData;
}

export const getUserInitials = (userName: string) => {
    return userName.split(" ").map((n)=>n[0]).join("");
}

export const getProcessedWorkingData = (startDateStr: string, endDateStr: string, data: WorkingEfficiencyData[]) : WorkingEfficiency => {
    let localData = data.map( x => {
        x.startDate = dayjs.utc(x.startDate).local()
        return x
    });
    let hourlyWorkingEfficiency: HeatmapData[] = [];
    let shiftBasedMovementEfficiency: HeatmapData[] = [];
    let workingAsMinutes: number[][] = [];
    let operators: string[][] = [];
    let operatorsShift: string[][] = [];
    let workingAsMinutesShift: number[][] = [];
    let startDate = dayjs(startDateStr);
    let endDate = dayjs(endDateStr);
    for (var d = startDate; d.isBefore(endDate); d = d.add(1, 'day')) {
        let dayData = localData.filter(x => dayjs(x.startDate).isSame(d, 'date'));
        let dayValues: number[] = [];
        let workingAsMinutesInDay: number[] = [];
        let operatorsInDay: string[] = [];
        for(let i = 0; i < 24; i++){
            let hourlyData = dayData.filter(x => dayjs(x.startDate).minute(0).second(0).millisecond(0).isSame(d.hour(i).minute(0).second(0).millisecond(0)))
            if(hourlyData.length > 0){
                dayValues.push(hourlyData[0].efficiency)
                workingAsMinutesInDay.push(hourlyData[0].workingAsMinute);
                operatorsInDay.push(hourlyData[0].operators);
            }else{
                dayValues.push(0)
                workingAsMinutesInDay.push(0);
                operatorsInDay.push("");
            }
        }
        workingAsMinutes.push(workingAsMinutesInDay.reverse());
        operators.push(operatorsInDay.reverse());
        operatorsInDay = operatorsInDay.reverse();
        let firstShiftOperators = operatorsInDay.slice(0,8).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(0,8).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        let secondShiftOperators = operatorsInDay.slice(8,16).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(8,16).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        let thirdShiftOperators = operatorsInDay.slice(16,24).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(16,24).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        operatorsShift.push([firstShiftOperators, secondShiftOperators, thirdShiftOperators]);
        dayValues = dayValues.reverse();
        let firstShift = dayValues.slice(0,8).filter(x => x === null).length === 8 ? null : Math.ceil(dayValues.slice(0,8).reduce((partialSum, a) => partialSum + a, 0) / 8);
        let secondShift = dayValues.slice(8,16).filter(x => x === null).length === 8 ? null : Math.ceil(dayValues.slice(8,16).reduce((partialSum, a) => partialSum + a, 0) / 8);
        let thirdShift = dayValues.slice(16,24).filter(x => x === null).length === 8 ? null : Math.ceil(dayValues.slice(16,24).reduce((partialSum, a) => partialSum + a, 0) / 8);
        let firstShiftMinutes = Math.ceil(workingAsMinutesInDay.slice(0,8).reduce((partialSum, a) => partialSum + a, 0));
        let secondShiftMinutes = Math.ceil(workingAsMinutesInDay.slice(8,16).reduce((partialSum, a) => partialSum + a, 0));
        let thirdShiftMinutes = Math.ceil(workingAsMinutesInDay.slice(16,24).reduce((partialSum, a) => partialSum + a, 0));
        workingAsMinutesShift.push([firstShiftMinutes, secondShiftMinutes, thirdShiftMinutes]);
        hourlyWorkingEfficiency.push({
            date: d.format('LL'),
            value: dayValues
        })
        shiftBasedMovementEfficiency.push({
            date: d.format('LL'),
            value: [firstShift, secondShift, thirdShift]
        })
    }
    return {
        hourlyWorkingEfficiency,
        shiftBasedWorkingEfficiency: shiftBasedMovementEfficiency,
        workingAsMinutes,
        workingAsMinutesShift,
        operators,
        operatorsShift
    } as WorkingEfficiency
}

export const getProcessedMovementData = (startDateStr: string, endDateStr: string, data: MovementEfficiencyData[]) : MovementEfficiency => {
    let hourlyWorkingEfficiency: HeatmapData[] = [];
    let shiftBasedMovementEfficiency: HeatmapData[] = [];
    let localData = data.map( x => {
        x.startDate = dayjs.utc(x.startDate).local()
        return x
    });
    let startDate = dayjs(startDateStr);
    let endDate = dayjs(endDateStr);
    let movementAsMinutes: number[][] = [];
    let movementAsMinutesShift: number[][] = [];
    let operators: string[][] = [];
    let operatorsShift: string[][] = [];
    for (var d = startDate; d.isBefore(endDate); d = d.add(1, 'day')) {
        let dayData = localData.filter(x => dayjs(x.startDate).isSame(d, 'date'));
        let dayValues: number[] = [];
        let movementAsMinutesInDay: number[] = [];
        let operatorsInDay: string[] = [];
        for(let i = 0; i < 24; i++){
            let hourlyData = dayData.filter(x => dayjs(x.startDate).minute(0).second(0).millisecond(0).isSame(d.hour(i).minute(0).second(0).millisecond(0)))
            if(hourlyData.length > 0){
                dayValues.push(hourlyData[0].efficiency)
                movementAsMinutesInDay.push(hourlyData[0].movementAsMinute);
                operatorsInDay.push(hourlyData[0].operators);
            }else{
                dayValues.push(0)
                movementAsMinutesInDay.push(0);
                operatorsInDay.push("");
            }
        }
        movementAsMinutes.push(movementAsMinutesInDay.reverse());
        operators.push(operatorsInDay.reverse());
        operatorsInDay = operatorsInDay.reverse();
        let firstShiftOperators = operatorsInDay.slice(0,8).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(0,8).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        let secondShiftOperators = operatorsInDay.slice(8,16).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(8,16).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        let thirdShiftOperators = operatorsInDay.slice(16,24).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(16,24).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        operatorsShift.push([firstShiftOperators, secondShiftOperators, thirdShiftOperators]);
        dayValues = dayValues.reverse();
        let firstShift = dayValues.slice(0,8).filter(x => x === null).length === 8 ? null : Math.ceil(dayValues.slice(0,8).reduce((partialSum, a) => partialSum + a, 0) / 8);
        let secondShift = dayValues.slice(8,16).filter(x => x === null).length === 8 ? null : Math.ceil(dayValues.slice(8,16).reduce((partialSum, a) => partialSum + a, 0) / 8);
        let thirdShift = dayValues.slice(16,24).filter(x => x === null).length === 8 ? null : Math.ceil(dayValues.slice(16,24).reduce((partialSum, a) => partialSum + a, 0) / 8);
        let firstShiftMinutesMovement = Math.ceil(movementAsMinutesInDay.slice(0,8).reduce((partialSum, a) => partialSum + a, 0));
        let secondShiftMinutesMovement = Math.ceil(movementAsMinutesInDay.slice(8,16).reduce((partialSum, a) => partialSum + a, 0));
        let thirdShiftMinutesMovement = Math.ceil(movementAsMinutesInDay.slice(16,24).reduce((partialSum, a) => partialSum + a, 0));
        movementAsMinutesShift.push([firstShiftMinutesMovement, secondShiftMinutesMovement, thirdShiftMinutesMovement]);
        hourlyWorkingEfficiency.push({
            date: d.format('LL'),
            value: dayValues
        })
        shiftBasedMovementEfficiency.push({
            date: d.format('LL'),
            value: [firstShift, secondShift, thirdShift]
        })
    }
    return {
        hourlyMovementEfficiency: hourlyWorkingEfficiency,
        shiftBasedMovementEfficiency: shiftBasedMovementEfficiency,
        movementAsMinutes: movementAsMinutes,
        movementAsMinutesShift: movementAsMinutesShift,
        operators,
        operatorsShift
    } as MovementEfficiency
}

export const getProcessedUsageData = (startDateStr: string, endDateStr: string, data: UsageEfficiencyData[]) : UsageEfficiency => {
    let hourlyWorkingEfficiency: HeatmapData[] = [];
    let shiftBasedUsageEfficiency: HeatmapData[] = [];
    let localData = data.map( x => {
        x.startDate = dayjs.utc(x.startDate).local()
        return x
    });
    let startDate = dayjs(startDateStr);
    let endDate = dayjs(endDateStr);
    let movementAsMinutes: number[][] = [];
    let workingAsMinutes: number[][] = [];
    let workingAsMinutesShift: number[][] = [];
    let movementAsMinutesShift: number[][] = [];
    let operators: string[][] = [];
    let operatorsShift: string[][] = [];
    for (var d = startDate; d.isBefore(endDate); d = d.add(1, 'day')) {
        let dayData = localData.filter(x => dayjs(x.startDate).isSame(d, 'date'));
        let dayValues: number[] = [];
        let movementAsMinutesInDay: number[] = [];
        let workingAsMinutesInDay: number[] = [];
        let operatorsInDay: string[] = [];
        for(let i = 0; i < 24; i++){
            let hourlyData = dayData.filter(x => dayjs(x.startDate).minute(0).second(0).millisecond(0).isSame(d.hour(i).minute(0).second(0).millisecond(0)))
            if(hourlyData.length > 0){
                dayValues.push(hourlyData[0].efficiency)
                movementAsMinutesInDay.push(hourlyData[0].movementAsMinute);
                workingAsMinutesInDay.push(hourlyData[0].workingAsMinute);
                operatorsInDay.push(hourlyData[0].operators);
            }else{
                dayValues.push(0)
                movementAsMinutesInDay.push(0);
                workingAsMinutesInDay.push(0);
                operatorsInDay.push("");
            }
        }
        movementAsMinutes.push(movementAsMinutesInDay.reverse());
        workingAsMinutes.push(workingAsMinutesInDay.reverse());
        operators.push(operatorsInDay.reverse());
        operatorsInDay = operatorsInDay.reverse();
        let firstShiftOperators = operatorsInDay.slice(0,8).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(0,8).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        let secondShiftOperators = operatorsInDay.slice(8,16).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(8,16).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        let thirdShiftOperators = operatorsInDay.slice(16,24).filter(x => x === null || x === "" || x === "[]").length === 8 ? "" : operatorsInDay.slice(16,24).filter(x => x !== "" && x !== "[]").filter((val,id,array) => array.indexOf(val) == id).join(",");
        operatorsShift.push([firstShiftOperators, secondShiftOperators, thirdShiftOperators]);
        dayValues = dayValues.reverse();
        let firstShiftMinutes = Math.ceil(workingAsMinutesInDay.slice(0,8).reduce((partialSum, a) => partialSum + a, 0));
        let secondShiftMinutes = Math.ceil(workingAsMinutesInDay.slice(8,16).reduce((partialSum, a) => partialSum + a, 0));
        let thirdShiftMinutes = Math.ceil(workingAsMinutesInDay.slice(16,24).reduce((partialSum, a) => partialSum + a, 0));
        let firstShiftMinutesMovement = Math.ceil(movementAsMinutesInDay.slice(0,8).reduce((partialSum, a) => partialSum + a, 0));
        let secondShiftMinutesMovement = Math.ceil(movementAsMinutesInDay.slice(8,16).reduce((partialSum, a) => partialSum + a, 0));
        let thirdShiftMinutesMovement = Math.ceil(movementAsMinutesInDay.slice(16,24).reduce((partialSum, a) => partialSum + a, 0));
        let firstShift = !!firstShiftMinutes && firstShiftMinutes !== 0 ? Math.ceil(firstShiftMinutesMovement * 100 / firstShiftMinutes) : !!firstShiftMinutes && firstShiftMinutes === 0 ? 0 : null;
        let secondShift = !!secondShiftMinutes && secondShiftMinutes !== 0 ? Math.ceil(secondShiftMinutesMovement * 100 / secondShiftMinutes) : !!secondShiftMinutes && secondShiftMinutes === 0 ? 0 : null;
        let thirdShift = !!thirdShiftMinutes && thirdShiftMinutes !== 0 ? Math.ceil(thirdShiftMinutesMovement * 100 / thirdShiftMinutes) : !!thirdShiftMinutes && thirdShiftMinutes === 0 ? 0 : null;
        workingAsMinutesShift.push([firstShiftMinutes, secondShiftMinutes, thirdShiftMinutes]);
        movementAsMinutesShift.push([firstShiftMinutesMovement, secondShiftMinutesMovement, thirdShiftMinutesMovement]);
        hourlyWorkingEfficiency.push({
            date: d.format('LL'),
            value: dayValues
        })
        shiftBasedUsageEfficiency.push({
            date: d.format('LL'),
            value: [firstShift, secondShift, thirdShift]
        })
    }
    return {
        hourlyUsageEfficiency: hourlyWorkingEfficiency,
        shiftBasedUsageEfficiency: shiftBasedUsageEfficiency,
        workingAsMinutes: workingAsMinutes,
        workingAsMinutesShift: workingAsMinutesShift,
        movementAsMinutes: movementAsMinutes,
        movementAsMinutesShift: movementAsMinutesShift,
        operators,
        operatorsShift
    } as UsageEfficiency
}

export const getTitle = (type: string, forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], heatmapType: HeatmapType, startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    if(forklifts.length > 0) {
        let reportVariables = (allForklifts.filter(x => forklifts.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(tags.length > 0) {
        let reportVariables = (allTags.filter(x => tags.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(departments.length > 0) {
        let reportVariables = (allDepartments.filter(x => departments.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }

    if(companies.length > 0) {
        let reportVariables = (allCompanies.filter(x => companies.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu." + type)} ${reportVariables} ${heatmapType === HeatmapType.Hourly ? t("common.hourly") : t("common.shift_based")} ${startDate} - ${endDate}`
    }
}

export const efficiencyTypes = (t: TFunction<"translation", undefined, "translation">) => {
    return [{
        label: t("common.hourly"),
        value: HeatmapType.Hourly
    }, {
        label: t("common.shift_based"),
        value: HeatmapType.Shift
    }];
}

export const efficiencyForceRender = (setForceRender: React.Dispatch<React.SetStateAction<boolean>>) => {
    setForceRender(true);
    setTimeout(() => {setForceRender(false)},500);
}

export const efficiencySubmit = (values: any, setStartDate: (value: React.SetStateAction<string>) => void, setEndDate: (value: React.SetStateAction<string>) => void, t: TFunction<"translation", undefined, "translation">) => {
    efficiencySetDates(setStartDate, setEndDate, values);
    efficiencyValidation(values, t);
}

export const efficiencySetDates = (setStartDate: (value: React.SetStateAction<string>) => void, setEndDate: (value: React.SetStateAction<string>) => void, values: any) => {
    setStartDate(dayjs(values.date[0]).format('LL'))
    setEndDate(dayjs(values.date[1]).format('LL'))
}

export const efficiencyValidation = (values: any, t: TFunction<"translation", undefined, "translation">) => {
    if(values.forkliftIds.length < 1 && values.departmentIds.length < 1 && values.companyIds.length < 1){
        openNotification('error', t('common.error'), t('common.error_info'));
        return
    }
}

export const efficiencyRequestPrepare = (values: any) => {
    return {
        companyIds: values.companyIds as string[],
        departmentIds: values.departmentIds as string[],
        forkliftIds: values.forkliftIds as string[],
        startDate: dayjs(values.date[0]).hour(0).minute(0).second(0).millisecond(0).toISOString(),
        endDate:  dayjs(values.date[1]).hour(23).minute(59).second(59).millisecond(0).toISOString()
    }
}

export const useGetMovementDataMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: MovementEfficiencyRequest }) => getMovementData(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setMovementEfficiency(getProcessedMovementData(variables.req.startDate, variables.req.endDate, data)))            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const useGetWorkingDataMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: WorkingEfficiencyRequest }) => getWorkingData(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setWorkingEfficiency(getProcessedWorkingData(variables.req.startDate, variables.req.endDate, data)))            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const useGetUsageDataMutation = (t: TFunction<"translation", undefined, "translation">) => {
    return useMutation(({ req }: { req: UsageEfficiencyRequest }) => getUsageData(req), {
        onSuccess: (data, variables) => {
            store.dispatch(setUsageEfficiency(getProcessedUsageData(variables.req.startDate, variables.req.endDate, data)))            
        },
        onError: () => {
            openNotification('error', t('common.error'), t('common.error_info'));
        }
    })
}

export const efficiencyReset = () => {
    store.dispatch(setPeriodUsage(TimePeriod.Daily));
    store.dispatch(setCompaniesUsage([]));
    store.dispatch(setDepartmentsUsage([]));
    store.dispatch(setForklftsUsage([]));
    store.dispatch(setTagsUsage([]));

    store.dispatch(setPeriodMovement(TimePeriod.Daily));
    store.dispatch(setCompaniesMovement([]));
    store.dispatch(setDepartmentsMovement([]));
    store.dispatch(setForklftsMovement([]));
    store.dispatch(setTagsMovement([]));

    store.dispatch(setPeriodWorking(TimePeriod.Daily));
    store.dispatch(setCompaniesWorking([]));
    store.dispatch(setDepartmentsWorking([]));
    store.dispatch(setForklftsWorking([]));
    store.dispatch(setTagsWorking([]));
}

export const efficiencySetCompanies = (companies: string[]) => {
    store.dispatch(setCompaniesUsage(companies));
    store.dispatch(setCompaniesMovement(companies));
    store.dispatch(setCompaniesWorking(companies));
}

export const efficiencySetDepartments = (departments: string[]) => {
    store.dispatch(setDepartmentsUsage(departments));
    store.dispatch(setDepartmentsMovement(departments));
    store.dispatch(setDepartmentsWorking(departments));
}

export const efficiencySetForklifts = (forklifts: string[]) => {
    store.dispatch(setForklftsUsage(forklifts));
    store.dispatch(setForklftsMovement(forklifts));
    store.dispatch(setForklftsWorking(forklifts));
}

export const efficiencySetTags = (tags: string[]) => {
    store.dispatch(setTagsUsage(tags));
    store.dispatch(setTagsMovement(tags));
    store.dispatch(setTagsWorking(tags));
}

export const efficiencySetPeriod = (period: TimePeriod) => {
    store.dispatch(setPeriodUsage(period));
    store.dispatch(setPeriodMovement(period));
    store.dispatch(setPeriodWorking(period));
}

export const efficiencySetHeatmapType = (heatmapType: HeatmapType) => {
    store.dispatch(setHeatmapTypeUsage(heatmapType));
    store.dispatch(setHeatmapTypeMovement(heatmapType));
    store.dispatch(setHeatmapTypeWorking(heatmapType));
}

export const efficiencySetShowFilters = (showFilters: boolean) => {
    store.dispatch(setShowFiltersUsage(showFilters));
    store.dispatch(setShowFiltersMovement(showFilters));
    store.dispatch(setShowFiltersWorking(showFilters));
}

export const efficiencySetDefaults = () => {
    store.dispatch(setPeriodUsage(TimePeriod.Daily))
    store.dispatch(setPeriodMovement(TimePeriod.Daily))
    store.dispatch(setPeriodWorking(TimePeriod.Daily))
    store.dispatch(setHeatmapTypeUsage(HeatmapType.Hourly))
    store.dispatch(setHeatmapTypeMovement(HeatmapType.Hourly))
    store.dispatch(setHeatmapTypeWorking(HeatmapType.Hourly))
    store.dispatch(setShowFiltersUsage(true))
    store.dispatch(setShowFiltersMovement(true))
    store.dispatch(setShowFiltersWorking(true))
}

export const efficiencyGetType = (efficiencyType: EfficienyType) => {
    switch (efficiencyType) {
        case EfficienyType.Usage:
            return 'usageEfficieny';
        case EfficienyType.Working:
            return 'workingEfficieny';
        case EfficienyType.Movement:
            return 'movementEfficieny';
        default:
            return '';
    }
}

export const efficienyGetData = (efficienyType: EfficienyType, heatmapType: HeatmapType, usageEfficiency: UsageEfficiency, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency) => {
    if(efficienyType === null || heatmapType === null)
        return [];

    switch (efficienyType) {
        case EfficienyType.Usage:
            return heatmapType === HeatmapType.Hourly ? usageEfficiency?.hourlyUsageEfficiency : usageEfficiency?.shiftBasedUsageEfficiency;
        case EfficienyType.Working:
            return heatmapType === HeatmapType.Hourly ? workingEfficiency?.hourlyWorkingEfficiency : workingEfficiency?.shiftBasedWorkingEfficiency;
        case EfficienyType.Movement:
            return heatmapType === HeatmapType.Hourly ? movementEfficiency?.hourlyMovementEfficiency : movementEfficiency?.shiftBasedMovementEfficiency;
        default:
            return heatmapType === HeatmapType.Hourly ? usageEfficiency?.hourlyUsageEfficiency : usageEfficiency?.shiftBasedUsageEfficiency;
    }
}

export const efficienyDownloadExcel = (efficienyType: EfficienyType, heatmapType: HeatmapType, usageEfficiency: UsageEfficiency, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency, type: string, forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    let downloadData:any = [];
    efficienyGetData(efficienyType, heatmapType, usageEfficiency, workingEfficiency, movementEfficiency).forEach(x => {
        let rowData: any = {};
        rowData[t("common.date")] = x.date;
        const reversedArray:any = [];
        for (let i = x.value.length - 1; i >= 0; i--) {
            reversedArray.push(x.value[i]);
        }
        let labels = prepareHeatmapLabels(heatmapType);
        labels.reverse().forEach((label, index) => {
            rowData[label] = reversedArray[index];
        })
        downloadData.push(rowData);
    });
    const worksheet = XLSX.utils.json_to_sheet(downloadData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${getTitle(efficiencyGetType(efficienyType), forklifts, allForklifts, tags, allTags, departments, allDepartments, companies, allCompanies, heatmapType, startDate, endDate, t)}.xlsx`);
};

export const prepareEfficiencyLabels = (isHeatmap: boolean) => {
    let labels = [];
    labels.push(`Sıra No`);
    labels.push(`Tarih`);
    labels.push(`Forklift İsmi`);
    if (isHeatmap) {
        labels.push(`Toplam Çalışma Dakikası`);
        labels.push(`Hareket Dakikası`);
        labels.push(`Rölanti Dakikası`);
        labels.push(`Operatör`);
    } else {
        labels.push(`Toplam Çalışma Saati`);
        labels.push(`Hareket Saati`);
        labels.push(`Rölanti Saati`);
    }
    labels.push(`Rölanti Oranı`);
    labels.push(`Açıklama`);
    return labels;
}

export const efficienyGetMinutes = (key: TimePeriod, dailyEfficiency: EfficiencySummary[], weeklyEfficiency: EfficiencySummary[], yearlyEfficiency: EfficiencySummary[]): EfficiencySummary[] => {
    if(key === null)
        return [];

    if(key == TimePeriod.Daily) {
        return dailyEfficiency
    } else if(key == TimePeriod.Weekly) {
        return weeklyEfficiency
    } else {
        return yearlyEfficiency
    }
}

export const efficiencyGetDate = (key: TimePeriod, date: dayjs.Dayjs) => {
    if(key === null)
        return '';

    if(key == TimePeriod.Daily) {
        return date.format("DD MMM");
    } else if(key == TimePeriod.Weekly) {
        return `${dayjs(date).format("YYYY")}-${dayjs(date).week()}.`;
    } else {
        return `${dayjs(date).format("YYYY")}-${date.format("MMM")}.`;
    }
}

export const efficiencyGetActualDate = (key: TimePeriod, date: dayjs.Dayjs, i: number, index:number) => {
    if(key == TimePeriod.Daily) {
        return date.add(i, 'days');
    } else if(key == TimePeriod.Weekly) {
        return dayjs(date).add(i, 'weeks');
    } else {
        return date.set('month', 1).add((i-1), 'months');
    }
}

export const customEfficienyDownloadExcel = (key: TimePeriod, dailyEfficiency: EfficiencySummary[], weeklyEfficiency: EfficiencySummary[], yearlyEfficiency: EfficiencySummary[], forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], startDateDayJs: dayjs.Dayjs, endDateDayJs: dayjs.Dayjs, startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    let downloadData:any = [];
    let excelData = efficienyGetMinutes(key, dailyEfficiency, weeklyEfficiency, yearlyEfficiency);
    let rowCount = 0;
    for (let index = 0; index < excelData.length; index = index+3) {
        let labels = prepareEfficiencyLabels(false);
        let dayCount = 0;
        let workingDataCopy = [...excelData[index].data];
        let movementDataCopy = [...excelData[index+1].data];
        let workingRealData = workingDataCopy.reverse();
        let movementRealData = movementDataCopy.reverse();
        if(key == TimePeriod.Monthly) {
            for (let i = 0; i <= excelData[index].data.length - 2; i++) {
                let rowData: any = {};
                let forkliftWorking =(excelData[index].data[i]);
                let forkliftMovement = (excelData[index+1].data[i]);
                let forkliftUsage = (excelData[index].data[i] - excelData[index+1].data[i]);
                rowData[labels[0]] = ++rowCount;
                rowData[labels[1]] = efficiencyGetDate(key, efficiencyGetActualDate(key, startDateDayJs, dayCount++, i));
                rowData[labels[2]] = (excelData[index].name).split(" - ")[0];            
                rowData[labels[3]] = getHoursAndMinutes(forkliftWorking);
                rowData[labels[4]] = getHoursAndMinutes(forkliftMovement);
                rowData[labels[5]] = getHoursAndMinutes(forkliftUsage);
                rowData[labels[6]] = ((100 * forkliftUsage) / forkliftWorking).toFixed(2);
                rowData[labels[7]] = '';
                downloadData.push(rowData);
            }
        }else{
            for (let i = excelData[index].data.length - 1; i >= 0; i--) {
                let rowData: any = {};
                let forkliftWorking =(workingRealData[i]);
                let forkliftMovement = (movementRealData[i]);
                let forkliftUsage = (workingRealData[i] - movementRealData[i]);
                rowData[labels[0]] = ++rowCount;
                rowData[labels[1]] = efficiencyGetDate(key, efficiencyGetActualDate(key, startDateDayJs, dayCount++, i));
                rowData[labels[2]] = (excelData[index].name).split(" - ")[0];            
                rowData[labels[3]] = getHoursAndMinutes(forkliftWorking);
                rowData[labels[4]] = getHoursAndMinutes(forkliftMovement);
                rowData[labels[5]] = getHoursAndMinutes(forkliftUsage);
                rowData[labels[6]] = ((100 * forkliftUsage) / forkliftWorking).toFixed(2);
                rowData[labels[7]] = '';
                downloadData.push(rowData);
            }
        }
    }
    const worksheet = XLSX.utils.json_to_sheet(downloadData);
    const workbook = XLSX.utils.book_new();

    // Calculate column widths
    const wscols = calculateColumnWidths(downloadData);
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${getEffiiciencySummaryTitle(forklifts, allForklifts, tags, allTags, departments, allDepartments, companies, allCompanies, startDate, endDate, t)}.xlsx`);
};

export const getHoursAndMinutes = (value: number) => {
    if(value < 60)
        {
          return `00:${toHoursAndMinutes(value).minutes < 10 ? '0' + toHoursAndMinutes(value).minutes : toHoursAndMinutes(value).minutes.toString()}:00`;
        }
        return `${toHoursAndMinutes(value).hours}:${toHoursAndMinutes(value).minutes < 10 ? '0' + toHoursAndMinutes(value).minutes : toHoursAndMinutes(value).minutes.toString()}:00`;
}

// Utility function to calculate column widths
const calculateColumnWidths = (data: any) => {
    const wscols = [];
  
    // Iterate over each key in the data objects
    const keys = Object.keys(data[0]);
    for (let i = 0; i < keys.length; i++) {
      let maxWidth = keys[i].length; // Start with header length
      data.forEach((row: any) => {
        const cellLength = row[keys[i]] ? row[keys[i]].toString().length : 10;
        if (cellLength > maxWidth) {
          maxWidth = cellLength;
        }
      });
      wscols.push({ wch: maxWidth });
    }
  
    return wscols;
};

export const getEffiiciencySummaryTitle = (forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    if(forklifts.length > 0) {
        let reportVariables = (allForklifts.filter(x => forklifts.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(tags.length > 0) {
        let reportVariables = (allTags.filter(x => tags.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(departments.length > 0) {
        let reportVariables = (allDepartments.filter(x => departments.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }

    if(companies.length > 0) {
        let reportVariables = (allCompanies.filter(x => companies.includes(x.id)).map(x => x.name).join(', '))
        return `${t("menu.efficiencySummary")} ${reportVariables} ${startDate} - ${endDate}`
    }
}

export const efficienyHeatmapGetMinutes = (key: HeatmapType, efficienyType: EfficienyType, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency, usageEfficiency: UsageEfficiency,): any => {
    if(key === null)
        return [];

    if(key == HeatmapType.Hourly) {
        return {
            movementAsMinutes: movementEfficiency.movementAsMinutes,
            workingAsMinutes: workingEfficiency.workingAsMinutes,
            operators: workingEfficiency.operators
        }
    } else if(key == HeatmapType.Shift) {
        return {
            movementAsMinutes: movementEfficiency.movementAsMinutesShift,
            workingAsMinutes: workingEfficiency.workingAsMinutesShift,
            operators: workingEfficiency.operatorsShift
        }
    }
}

export const customEfficienyHeatmapDownloadExcel = (key: HeatmapType, workingEfficiency: WorkingEfficiency, movementEfficiency: MovementEfficiency, usageEfficiency: UsageEfficiency, efficienyType: EfficienyType, forklifts: string[], allForklifts: Forklift[], tags: string[], allTags: ForkliftTag[], departments: string[], allDepartments: Department[], companies: string[], allCompanies: Company[], startDate: string, endDate: string, t: TFunction<"translation", undefined, "translation">) => {
    let downloadData:any = [];
    console.log(efficienyHeatmapGetMinutes(key, efficienyType, workingEfficiency, movementEfficiency, usageEfficiency))
};