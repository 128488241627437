import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAlarmSetsState from './alarm-sets-initial-state';
import { AlarmSets } from '../models/alarm-sets.model';

const alarmSetsSlice = createSlice({
    name: 'alarmSetsSlice',
    initialState: initialAlarmSetsState,
    reducers: {
        setSelectedCompany(state, { payload }: PayloadAction<string>) {
            state.selectedCompany = payload;
        },
        setAlarmSetList(state, { payload }: PayloadAction<AlarmSets[]>) {
            state.alarmSetList = payload;
        }
    }
});

export const {
    setAlarmSetList,
    setSelectedCompany
} = alarmSetsSlice.actions

export default alarmSetsSlice.reducer