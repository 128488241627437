import { Dayjs } from "dayjs";
import { TimePeriod } from "../../../../shared/enums/timePeriods.enum";
import { DateModel } from "../../../../shared/models/date.model";
import { OperatorAnalysis } from "../models/operatorAnalysis.model";

export interface OperatorAnalysisInitialState {
    selectedKey: TimePeriod;
    dailyOperatorAnalysis: OperatorAnalysis | null;
    weeklyOperatorAnalysis: OperatorAnalysis | null;
    yearlyOperatorAnalysis: OperatorAnalysis | null;
    dailyUsageOperatorAnalysis: OperatorAnalysis[];
    weeklyUsageOperatorAnalysis: OperatorAnalysis[];
    yearlyUsageOperatorAnalysis: OperatorAnalysis[];
    companies: string[];
    departments: string[];
    operators: string[];
    dates: DateModel;
    showFilters: boolean;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
}

const initialOperatorAnalysisState: OperatorAnalysisInitialState = {
    selectedKey: TimePeriod.Daily,
    dailyOperatorAnalysis: null,
    weeklyOperatorAnalysis: null,
    yearlyOperatorAnalysis: null,
    dailyUsageOperatorAnalysis: [],
    weeklyUsageOperatorAnalysis: [],
    yearlyUsageOperatorAnalysis: [],
    companies: [],
    departments: [],
    operators: [],
    dates: {
        startDate: null,
        endDate: null
    },
    showFilters: true,
    startDate: null,
    endDate: null
}

export default initialOperatorAnalysisState